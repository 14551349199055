// =======================================
//     List of variables for layout
// =======================================
/*
    description Of variables for build for theme layouts
        1) menu-caption-color
            List of color for sidebar menu caption

        2) brand-background
            List of color for logo background

        3) header-dark-background
            List of color for Dark Header

        4) header-light-background
            List of color for light Header

        5) menu-dark-background
            List of color for Dark sidebar menu

        6) menu-light-background
            List of color for light sidebar menu

        7) menu-active-color
            List of color for Active item highliter

        8) menu-icon-color
            List of color for sidebar menu items icon colors
*/
$header-height: 70px;
$Menu-width: 264px;
$Menu-collapsed-width: 80px;

$new-primary: #223b73;
$new-secondary: #2c4c94;
$new-warning: #e6b50a;

// color list for build layouts
// temp variables
//this is set for the new color theme
// $theme-color: linear-gradient(-135deg, #67c9ff 0%, #50a3ee 100%);
//$theme-color: #50a3ee;

$theme-color2: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
$primary-color: #04a9f5;
$warning-color: #f4c22b;
$danger-color: #f44236;
$success-color: #1de9b6;
$purple-color: #a389d4;
//$info-color: #3ebfea;
$info-color: #4482ad;
$secondary-color: #748892;
$default-color: #1f0e0e;
$light-color: #f2f2f2;
$dark-color: #37474f;
$inverse-color: $dark-color;
$theme-border: #eaeaea;

// new colors
$theme-color: linear-gradient(-135deg, #223b73 0%, #2c4c94 100%);
$theme-color-quiet: linear-gradient(-135deg, gray, darkgray);
$theme-colors-quiet: () !default;
$theme-colors-quiet: map-merge(
                (
                        "primary":    $new-primary,
                        "secondary":  $new-secondary,
                        "success":    $success-color,
                        "info":       $info-color,
                        "warning":    $new-warning,
                        "danger":     $danger-color,
                        "light":      $light-color,
                        "dark":       $dark-color,
                        "gray":       gray,
                ),
                $theme-colors-quiet
);

$theme-color-green: linear-gradient(-135deg, #b4efb4, #a3ec81);

// header
$brand-background: #3f4d67;
// $brand-background: #51A4F0;

$header-dark-background: #51a4f0;
$header-dark-text-color: #fff;
$header-light-background: #fff;
$header-light-text-color: #3f4d67;

// Menu
$menu-caption-color: white;

// $menu-caption-color: #14171d;
// $menu-dark-background: #3f4d67;  //back ground nav
//$menu-dark-background: #51a4f0;  //back ground nav
$menu-dark-background: #223B73;

// $menu-dark-text-color: #a9b7d0; //text color
$menu-dark-text-color: white; //text color

$menu-light-background: #fff;

$menu-light-text-color: #3f4d67;
//$menu-active-color: #04a9f5;
$menu-active-color: #2C4C94;
$menu-focus-color: #51a4f0;
$menu-hovor-color: #e6b50a;

// dark layout colors
$dark-layout: #212224;
$dark-layout-font: white;

// Menu icon
$menu-icon-color: $primary-color, #ff5252, #01a9ac, #9575cd, #23b7e5, $warning-color;
// Header background
$color-header-name: blue, red, purple, lightblue, dark;
$color-header-color: $primary-color, #ff5252, #9575cd, #23b7e5, lighten($dark-layout, 7%);
// Menu background
$color-menu-name: blue, red, purple, lightblue, dark;
$color-menu-color: $primary-color, #ff5252, #9575cd, #23b7e5, lighten($dark-layout, 7%);
// Active background color
$color-active-name: blue, red, purple, lightblue, dark;
$color-active-color: $primary-color, #ff5252, #9575cd, #23b7e5, lighten($dark-layout, 7%);
// Menu title color
$color-title-name: blue, red, purple, lightblue, dark;
$color-title-color: $primary-color, #ff5252, #9575cd, #23b7e5, lighten($dark-layout, 7%);

// layout-6 background color
$layout-6-bg-color: #23b7e5;

$theme-font-family: 'Open Sans', sans-serif;
$theme-font-size: 14px;
$theme-background: #f4f7fa;
$theme-font-color: #888;
$theme-heading-color: #111;

$color-name: c-blue, c-red, c-green, c-yellow, c-purple;
$color-color: $primary-color, $danger-color, $success-color, $warning-color, $purple-color;
$color-bt-name: primary, danger, success, warning, info;
$color-bt-color: $primary-color, $danger-color, $success-color, $warning-color, $info-color;

// form-control variables
$form-bg: #f0f3f6;

$theme-colors: () !default;
$theme-colors: map-merge(
    (
        'primary': $primary-color,
        'secondary': $secondary-color,
        'success': $success-color,
        'info': $info-color,
        'warning': $warning-color,
        'danger': $danger-color,
        'light': $light-color,
        'dark': $dark-color
    ),
    $theme-colors
); // Color contrast
$yiq-contrasted-threshold: 200 !default;
$yiq-dark-color: #37474f;
$yiq-light-color: #fff;
$theme-color-interval: 8% !default;
$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;
