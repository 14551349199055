.react-tel-input .selected-flag .arrow {
    position: relative;
    top: 50%;
    margin-top: -1px;
    left: 29px;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
    min-width: 0px;
    padding: 0px;
}

.css-g1d714-ValueContainer {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}

.css-yk16xz-control {
    background-color: #F4F7FA !important;
}