.theme-bar-block {
    > span {
        display: block;
        width: 12px;
        padding: 5px 0;
        height: 28px;
        float: left;
        background-color: #fbedd9;
        margin: 1px;
        text-align: center;
        &.active {
            background-color: #edb867;
        }
    }
}

.current-rating-block {
    font-size: 20px;
    float: left;
    padding: 0 20px 0 20px;
    color: #edb867;
    font-weight: 400;
}

.theme-bar-movie {
    > span {
        display: block;
        width: 60px;
        height: 8px;
        float: left;
        background-color: #bbcefb;
        margin: 1px;
        &.active {
            background-color: #4278f5;
        }
    }
}

.current-rating-movie {
    clear: both;
    width: 240px;
    text-align: center;
    font-weight: 600;
    display: block;
    padding: 0.5em 0;
    color: #4278f5;
}

.theme-bar-square {
    > span {
        display: block;
        width: 30px;
        height: 30px;
        float: left;
        border: 2px solid #bbcefb;
        background-color: white;
        margin: 2px;
        text-decoration: none;
        font-size: 14px;
        line-height: 2;
        text-align: center;
        color: #bbcefb;
        font-weight: 600;
        &.active {
            border: 2px solid #4278f5;
            color: #4278f5;
        }
    }
}

.pill-rating {
    .theme-bar-pill {
        > span {
            padding: 7px 15px;
            background-color: #bef5e8;
            color: #50e3c2;
            text-decoration: none;
            font-size: 13px;
            line-height: 3;
            text-align: center;
            font-weight: 400;
            &.active {
                background-color: #50e3c2;
                color: white;
            }
        }
    }
    > span > span {
        &:last-child {
            span {
                border-radius: 0 25px 25px 0;
            }
        }
        &:first-child {
            span {
                border-radius: 25px 0 0 25px;
            }
        }
    }
}

.reverse-rating {
    > span {
        direction: rtl !important;
    }
    .theme-bar-reverse {
        > span {
            display: block;
            width: 22px;
            height: 22px;
            float: left;
            background-color: #bef5e8;
            margin: 1px;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.4;
            color: #50e3c2;
            text-align: center;
            &.active {
                background-color: #50e3c2;
                color: white;
            }
        }
    }
}

.current-rating-reverse {
    line-height: 1.3;
    float: left;
    padding: 10px 0;
    color: #50e3c2;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
}

.horizontal-rating {
    width: 120px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);

    > span > span {
        display: block !important;
    }
    .theme-bar-horizontal {
        > span {
            display: block;
            width: 120px;
            height: 5px;
            background-color: #fbedd9;
            margin: 1px;
            &.active {
                background-color: #edb867;
            }
        }
    }
}

.current-rating-horizontal {
    width: 120px;
    font-size: 18px;
    font-weight: 600;
    line-height: 2;
    text-align: center;
    color: #edb867;
}
