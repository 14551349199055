body {
    overflow-x: hidden;
    /* font-family: "Century Gothic"; */

}



.email_textArea textarea {
    padding-bottom: 4rem;
}

.email_textArea {
    position: relative;
}

.select_template {
    position: absolute;
    bottom: 0.5rem;
    left: 1rem;
}

.selectApp{
    border:none !important;
    border: 1px solid lightgray !important;
}

/* // =>EmailTemplate Css */
.emailTemplateModal {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: 1px solid rgb(216, 214, 214);
    color: #000;
}

.hoverEffect:hover{
    background-color: #F4F7FA;
}

.emailTemplateModal li {
    position: relative;
}

.emailTemplateModal li a {
    color: #000;
    text-align: center;
    text-decoration: none;
    display: block;
    padding: 10px;
}

.overflowHidden {
    height: 200px;
    overflow: auto;
}

.emailTemplateModal li ul {
    z-index: 1;
    position: absolute;
    top: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: none;
    line-height: normal;
    background: #fff;
    width: 100%;
    color: #000;
}

.appInput{
    border-style: none;
    border:1px solid #CCCCCC;
}

.inFull>input{
    width: 49%;
}

.appInput::placeholder{
    color: lightgray;
    padding-left: 5px;
}

.inFull{
    display: flex;
    justify-content: space-between; 
}



.appForm{
    margin-right: 40px;
    padding: 10px;
    border-radius: 4px;
    color: white;
}

.appForm:hover{
    color:black;    
}


.emailTemplateModal li ul li a {
    text-align: left;
    color: #000;
    font-size: 14px;
    padding: 10px;
    display: block;
    white-space: nowrap;
}

/* .emailTemplateModal li ul li a:hover {
    background-color: ba;
    color: #000;
} */

.emailTemplateModal li ul li ul {
    left: 100%;
    top: 0;
}

.ello {
    height: 200px;
    overflow: auto;
}

.ello::-webkit-scrollbar {
    width: 12px;
    /* width of the entire scrollbar */
}

.ello::-webkit-scrollbar-track {
    padding: 1px;
    background: white;
    /* color of the tracking area */
}

#dropContainer:hover{
    
    cursor: pointer;
}

.ello::-webkit-scrollbar-thumb {
    background-color: gray;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 1px solid black;
    /* creates padding around scroll thumb */
}

/* .emailTemplateModal ul li:hover>a {
    background-color: #fff;
    color: #000 !important;
} */

.emailTemplateModal li:hover>ul {
    display: block;
}

.emailTemplateModal li {
    cursor: pointer;
}

.formButtons .theme-bg {
    margin-right: 1rem;
    color: #fff;
    /* background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%)!important; */
    background: #A0A0A0 !important;

}
.formButtons .active {
background: #04A9F5 !important;
}
/* //=>subContactFormCol SubContactList */
.formButtons1{
    margin-right: 1rem;
    color: #04A9F5 !important;
    /* background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%)!important; */
}



.subContactListColDetails .form-control-plaintext {
    margin-left: -1.8rem;
}
.subContactListColDetails .mx-0{
    margin-left: -40.9px!important;
}
.subContactListColDetails .col-sm-1{
    margin-left: -55px!important;
}

.imagePreviewORfilePreview  img{
    width: 30%!important;
    position: absolute!important;
    left: 32%!important;
    top: 6%!important;
}


.detailsSelector{
    /* height:60px; */
    /* border:1px solid red; */
    /*border-right:1px solid gray;*/
    /*border-left:1px solid gray;*/
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.arrowSp{
    /* border: 1px solid gray; */
    padding: 0;
    min-width: 100px;
    padding: 10px;
    margin:0;
    /* height:50px; */
    display: flex;
    align-items: center;
    justify-content: center;
    
    background-color: white;
    /* clip-path: polygon(0 20%, 80% 20%, 100% 50%, 80% 80%, 0 80%, 15% 50%); */
    clip-path: polygon(0 20%, 80% 20%, 100% 50%, 80% 80%, 0 80%);

}

.arrowSp:hover{
    cursor: pointer;
    background-color: #F1F1F1;
    padding: 10px;
}

.arrowSp>p{
    padding:0;
    margin:0;
    font-size: 10px;
    font-weight: bold;
    /* background-color: red; */
    
}

.removeOutline{
    outline: none !important;
    border: 1px solid lightgrey
}

.row-pass {
    font-weight: bold;
    background-color: #357a38 !important;
  }


table {
border-collapse: collapse;
}
th {
white-space: normal;
font-size: 12px;
}
.property-table-header th {
background-color: SteelBlue;
height: 50px;
}
tr.trow {
padding: 5px 0px 0px 0px;
}
td {
text-align: center;
}
td.td-fill {
background-color: LightBlue;
}
tr.table-total {
background-color: SteelBlue;
color: white;
font-weight: bold;
}
.proposal-sum {
margin-bottom: 60px;
}
.proposal-sum tr td {
font-size: 13px;
font-weight: bold;
}
.detail-container {
margin-bottom: 60px;
}
.detail-section {
break-inside: avoid;
margin-bottom: 15px;
}
.adr-icon {
width: 25px;
}
.address-line {
font-size: 25px;
font-weight: bold;
margin-bottom: 10px;
}
.cam-icon {
width: 25px;
}

.images-container {
break-inside: avoid;
padding-bottom: 15px;
}
.images-section {
break-inside: avoid;
}
.images-line {
font-size: 25px;
font-weight: bold;
margin-bottom: 10px;
}
.image-grid {
width: auto;
flex-wrap: wrap;
}
.image {
//object-fit: scale-down;
width: 275px;
height: 275px;
box-shadow: 3px 3px 3px 3px LightGray;
overflow: hidden;
margin-right: 20px;
margin-bottom: 20px;
}
.image > img {
position: relative;
left: 50%;
top: 50%;
transform: translateX(-50%) translateY(-50%);
object-fit: contain;
height: 275px;
}

ul {
padding: 0;
}

.flex {
display: flex;
}
.flex-item {
display: flex;
width: auto;
margin-left: 20px;

}
.list-style-none {
list-style-type: none;
}
.top {
vertical-align: text-top;
}
.bottom {
vertical-align: text-bottom;
}
.nowrap {
white-space: nowrap;
}
.left {
text-align: left;
}
.center {
text-align: center;
}
.right {
text-align: right;
}
.bold {
font-weight: bold;
}
.home-image {
width: 200px;
height: auto;
box-shadow: 3px 3px 3px 3px LightGray;
border-radius: 10px;
}
.area-map-image {
width: 100px;
border-radius: 10px;
}
.attr-icon {
width: 21px;
}
.attr {
font-size: 15px;
vertical-align: middle;
width: 100px;
}
.attr-item {
padding-bottom: 5px;
}
.status-icon {
width: 25px;
}

.hoverBtn:hover{
    cursor:pointer;
    background-color: green;
}

.btn-primary{
    /* background-color: #51a4f0 !important; */
    /*color: black;*/
    color: whitesmoke;
}

.float-left>h2{
    color:#51A4F0;
}